import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Importa i componenti delle pagine
import HomePage from '../pages/HomePage';
import Azionamento from '../pages/Azionamento';
import ProduttivitaPage from '../pages/Produttivita';


// Importa altre pagine se necessarie

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} /> {/* Utilizza element */}
      <Route path="/azionamento" element={<Azionamento />} /> {/* Utilizza element */}
      <Route path="/produttivita" element={<ProduttivitaPage />} /> {/* Utilizza element */}
      {/* Aggiungi altre route qui */}
    </Routes>
  );
};

export default AppRoutes;
