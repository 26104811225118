import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next'; // Importa l'hook di traduzione
import { languages } from '../locales/languages'; // Importa l'array delle lingue
import '../i18n'; // Importa la configurazione i18n
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import axios from 'axios'; // Importa Axios

//Importo Autenticazione Token
import getAuthToken from '../features/auth/axiosSetup';

//Richiamo il Context per il SetProdotti e Scelte
import { ProductsContext } from '../context/ProductsContext';
import { SelectionsContext } from '../context/SelectionsContext';

//Importo Toast di notifiche
import Toast from '../componets/toast';



export default function AzionamentoPage() {
  const { t, i18n } = useTranslation(); // Hook di traduzione
  const navigate = useNavigate(); // Inizializza l'hook useNavigate
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { setProducts } = useContext(ProductsContext); // Usa il contesto per salvare i prodotti
  const { selections, setSelections } = useContext(SelectionsContext);
  const { azionamento } = selections;
  const [selectedOptions, setSelectedOptions] = useState(azionamento || []);
  const [show, setShow] = useState(false)
  const [titolo, setTitolo] = useState('')
  const [messaggio, setMessaggio] = useState('')




 
  // Stato per la lingua selezionata
  const [selectedLanguage, setSelectedLanguage] = useState(languages.find(lang => lang.code === i18n.language));

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang.code); // Cambia la lingua
    setSelectedLanguage(lang); // Imposta la lingua selezionata
    setIsDropdownOpen(false); // Chiudi il dropdown dopo la selezione
  };

  const handleBackToHome = () => {
    navigate('/'); // Naviga verso la pagina Azionamento
  };
  
  //Funzioni per le selzioni
  const handleOptionSelect = (option) => {
    let updatedOptions;
    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((o) => o !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
    setSelections({ ...selections, azionamento: updatedOptions });
  };
  
  

  //Richiamo Backend per le selezioni e i prodotti
const handleSubmit = async () => {
  if (selectedOptions.length === 0) {
      console.log('Errore: nessuna opzione selezionata');

      //Stampo Errore e notifica
      setTitolo(t('azionamento.titolo_toast'));
      setMessaggio(t('azionamento.messaggio_toast'))
      setShow(true)

      //Chiudo il Toast è azzero i messaggi i titoli
      setTimeout(() => {
      setTitolo(t('azionamento.titolo_toast'));
      setMessaggio(t('azionamento.messaggio_toast'))
      setShow(false)
      }, 2000); 

      return; // Esci dalla funzione se non ci sono opzioni selezionate
  }

  try {
      // Ottieni il token in modo asincrono
      const token = await getAuthToken();
      // Se ci sono opzioni selezionate, procedi con la chiamata Axios
      const response = await axios.post(
          `${process.env.REACT_APP_API}/products/get-products`,
          { selectedOptions },
          {
              headers: {
                  'Authorization': `Bearer ${token}` // Usa il token ottenuto
              }
          }
      );
      console.log('Risposta ricevuta:', response.data);

      //Salvo il prodotti 
      setProducts(response.data);

      //Navigo verso la pagina di produttivita
      navigate('/produttivita')

      // Puoi gestire la risposta qui
  } catch (error) {
      console.error('Errore:', error);
  }
};

  return (
    <>
      <Helmet>
        <title> © {t('azionamento.head_title')} | Pezzolato Spa</title> {/* Cambia il titolo */}
        <meta name="description" content={t('home.meta_descr')} /> {/* Cambia i meta tag */}
      </Helmet>

      <div className="min-h-screen flex flex-col justify-between bg-white">
        {/* Header */}
        <header className="flex justify-between items-center w-full py-6 px-4 lg:px-32 bg-[#F2F2F2]">
          <button onClick={handleBackToHome}>
            <img src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`} alt="Pezzolato Logo" className="w-44" />
          </button>
          {/* Language Selector */}
          <div className="relative">
            <button onClick={toggleDropdown} className="flex items-center space-x-2">
              {selectedLanguage.flag}
              <span>{selectedLanguage.name}</span>
            </button>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 rounded shadow-lg z-20">
                <ul className="text-black text-sm">
                  {languages.map((lang) => (
                    <li
                      key={lang.code}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                      onClick={() => handleLanguageChange(lang)}
                    >
                      {lang.flag}
                      <span>{lang.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </header>

        {/* Progress bar */}
        <div className="flex justify-between w-full px-0 py-0">
          <div className="h-2 w-1/5 bk-color-green text-center pt-2"><span className='poppins-semibold text-color-s-green'>1</span></div>
          <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2"><span className='poppins-semibold text-color-s-grey'>2</span></div>
          <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2"><span className='poppins-semibold text-color-s-grey'>3</span></div>
          <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2"><span className='poppins-semibold text-color-s-grey'>4</span></div>
          <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2"><span className='poppins-semibold text-color-s-grey'>5</span></div>
        </div>

        {/* Main Content */}
        <main className="flex flex-1 justify-center items-center text-center my-14 lg:my-0">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-4xl lg:text-5xl font-semibold text-color-s-green mx-2">
              {t('azionamento.tipo_azionamento')}
            </h1>
            <p className="text-lg text-gray-600 mt-3 lg:mt-1">{t('azionamento.opzioni')}</p>

            {/* Options Grid */}
            <div className="flex flex-wrap justify-center items-center gap-4 mt-10">
              {/* Usa handleOptionSelect per gestire la selezione */}
              <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('presa_forza')}>
                <div style={{ borderWidth: selectedOptions.includes('presa_forza') ? '4px' : '1px' }} className={`rounded-lg p-4 w-60 h-60 flex justify-center items-center ${selectedOptions.includes('presa_forza') ? 'border-[#556423]' : 'border-[#707070]'}`}>
                  <img src={`${process.env.REACT_APP_FILESERVER}/presa-di-forza.png`} alt="Presa di forza" className="mx-auto" />
                </div>
                <h3 className="text-lg poppins-semibold text-color-box mt-2">{t('azionamento.presa_forza')}</h3>
                <p className="text-sm poppins-regular text-color-box">{t('azionamento.presa_di_forza_hp')}</p>
              </div>

              <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('autonomo')}>
                <div style={{ borderWidth: selectedOptions.includes('autonomo') ? '4px' : '1px' }} className={`rounded-lg p-4 w-60 h-60 flex justify-center items-center ${selectedOptions.includes('autonomo') ? 'border-[#556423]' : 'border-[#707070]'}`}>
                  <img src={`${process.env.REACT_APP_FILESERVER}/motore-autonomo.png`} alt="Motore Autonomo" className="mx-auto" />
                </div>
                {/* Testo sotto al box con altezza fissa */}
                <h3 className="text-lg poppins-semibold text-color-box h-7 mt-3 w-60 leading-[1] lg:leading-[1] flex items-center justify-center">{t('azionamento.motore_autonomo')}</h3>
                <p className="text-sm poppins-regular text-color-box h-0 mt-4 mb-3 lg:mb-0 flex items-center justify-center">{t('azionamento.motore_autonomo_hp')}</p>
              </div>

              <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('camion')}>
                <div style={{ borderWidth: selectedOptions.includes('camion') ? '4px' : '1px' }} className={`rounded-lg p-4 w-60 h-60 flex justify-center items-center ${selectedOptions.includes('camion') ? 'border-[#556423]' : 'border-[#707070]'}`}>
                  <img src={`${process.env.REACT_APP_FILESERVER}/camion.png`} alt="Camion" className="mx-auto" />
                </div>
                <h3 className="text-lg poppins-semibold text-color-box mt-2">{t('azionamento.camion')}</h3>
                <p className="text-sm poppins-regular text-color-box">{t('azionamento.camion_hp')}</p>
              </div>

              <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('hackertruck')}>
                <div style={{ borderWidth: selectedOptions.includes('hackertruck') ? '4px' : '1px' }} className={`rounded-lg p-4 w-60 h-60 flex justify-center items-center ${selectedOptions.includes('hackertruck') ? 'border-[#556423]' : 'border-[#707070]'}`}>
                  <img src={`${process.env.REACT_APP_FILESERVER}/hackertruck.png`} alt="Hackertruck" className="mx-auto" />
                </div>
                <h3 className="text-lg poppins-semibold text-color-box mt-2 h-12">{t('azionamento.hackertruck')}</h3>
                <p className="text-sm poppins-regular text-color-box"></p>
              </div>

              <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('elettrico')}>
                <div style={{ borderWidth: selectedOptions.includes('elettrico') ? '4px' : '1px' }} className={`rounded-lg p-4 w-60 h-60 flex justify-center items-center ${selectedOptions.includes('elettrico') ? 'border-[#556423]' : 'border-[#707070]'}`}>
                  <img src={`${process.env.REACT_APP_FILESERVER}/elettrico.png`} alt="Elettrico" className="mx-auto" />
                </div>
                <h3 className="text-lg poppins-semibold text-color-box mt-2 h-12">{t('azionamento.motore_elettrico')}</h3>
                <p className="text-sm poppins-regular text-color-box"></p>
              </div>
            </div>

            {/* Submit Button */}
            <button
              onClick={handleSubmit}
              style={{ borderWidth: '3px' }}
              className="border-[#556423] text-[#556423] text-xl lg:text-3xl uppercase font-bold py-4 w-60 lg:w-full rounded-full mt-10 lg:mt-20"
            >
              {t('azionamento.cta')}
            </button>
          </div>
        </main>

        {/* Footer */}
        <footer className="text-center py-6 bg-[#556423] text-white">
          <button>
            <p className="inline-flex items-center">
              {t('azionamento.footer')}
              <span className="font-bold ml-2">
                <img
                  src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                  alt="Pezzolato Logo"
                  className="w-32"
                />
              </span>
            </p>
          </button>
        </footer>
      </div>
     {/* Importo Notifiche */}
     <Toast show={show} setShow={setShow} titolo={titolo} messaggio={messaggio}/>
    </>
  );
}