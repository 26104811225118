import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next'; // Hook di traduzione
import { languages } from '../locales/languages'; // Importa l'array delle lingue
import '../i18n'; // Configurazione i18n
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import axios from 'axios'; // Importa Axios

// Importo Autenticazione Token
import getAuthToken from '../features/auth/axiosSetup';

// Richiamo il Context per il SetProdotti e Scelte
import { ProductsContext } from '../context/ProductsContext';
import { SelectionsContext } from '../context/SelectionsContext';

// Importo Toast di notifiche
import Toast from '../componets/toast';

export default function ProduttivitaPage() {
  const { t, i18n } = useTranslation(); // Hook di traduzione
  const navigate = useNavigate(); // Hook per la navigazione
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { setProducts } = useContext(ProductsContext);
  const { selections, setSelections } = useContext(SelectionsContext);
  const { produttivita } = selections;
  const [selectedOptions, setSelectedOptions] = useState(produttivita || []);
  const [show, setShow] = useState(false);
  const [titolo, setTitolo] = useState('');
  const [messaggio, setMessaggio] = useState('');

  const [selectedLanguage, setSelectedLanguage] = useState(languages.find(lang => lang.code === i18n.language));

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleBackToHome = () => {
    navigate('/'); // Naviga verso la pagina Azionamento
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang.code);
    setSelectedLanguage(lang);
    setIsDropdownOpen(false);
  };

  const handleOptionSelect = (option) => {
    let updatedOptions;
    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((o) => o !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
    setSelections({ ...selections, produttivita: updatedOptions });
  };

  const handleSubmit = async () => {
    if (selectedOptions.length === 0) {
      setTitolo(t('produttivita.titolo_toast'));
      setMessaggio(t('produttivita.messaggio_toast'));
      setShow(true);

      setTimeout(() => {
        setShow(false);
      }, 2000);
      return;
    }

    try {
      const token = await getAuthToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API}/products/get-products`,
        { selectedOptions },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      setProducts(response.data);
      navigate('/prodotti');
    } catch (error) {
      console.error('Errore:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>© {t('produttivita.head_title')} | Pezzolato Spa</title>
        <meta name="description" content={t('produttivita.meta_descr')} />
      </Helmet>

      <div className="min-h-screen flex flex-col justify-between bg-white">
        {/* Header */}
        <header className="flex justify-between items-center w-full py-6 px-4 lg:px-32 bg-[#F2F2F2]">
          <button onClick={handleBackToHome}>
            <img src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`} alt="Pezzolato Logo" className="w-44" />
          </button>
          {/* Language Selector */}
          <div className="relative">
            <button onClick={toggleDropdown} className="flex items-center space-x-2">
              {selectedLanguage.flag}
              <span>{selectedLanguage.name}</span>
            </button>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 rounded shadow-lg z-20">
                <ul className="text-black text-sm">
                  {languages.map((lang) => (
                    <li
                      key={lang.code}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                      onClick={() => handleLanguageChange(lang)}
                    >
                      {lang.flag}
                      <span>{lang.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </header>

        {/* Progress bar */}
        <div className="flex justify-between w-full px-0 pt-0" style={{ marginTop: '-3rem' }}>
          <div className="h-2 w-1/5 bk-color-green text-center pt-2"><span className='poppins-semibold text-color-s-green'>1</span></div>
          <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2"><span className='poppins-semibold text-color-s-grey'>2</span></div>
          <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2"><span className='poppins-semibold text-color-s-grey'>3</span></div>
          <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2"><span className='poppins-semibold text-color-s-grey'>4</span></div>
          <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2"><span className='poppins-semibold text-color-s-grey'>5</span></div>
        </div>

        {/* Main Content with Title and Two Columns */}
        <main className="flex flex-col justify-center items-center text-center my-14 w-full">
          {/* Titolo al centro */}
          <h1 className="text-4xl lg:text-5xl font-semibold text-color-s-green mx-2 mb-0 lg:mb-14 lg:mt-0">
            {t('azionamento.tipo_azionamento')}
          </h1>

          {/* Colonne per desktop e mobile */}
          <div className="flex flex-col lg:flex-row justify-center items-center text-center my-14 lg:my-0 w-full">
            <div className="flex flex-col lg:flex-row justify-between w-full max-w-5xl">
              {/* Left Column - Image */}
              <div className="flex justify-center items-center w-full lg:w-1/2 mb-6 lg:mb-0">
                <img
                  src={`${process.env.REACT_APP_FILESERVER}/produttiva.png`}
                  alt="Segnaposto"
                  className="w-9/12 object-contain"
                />
              </div>

              {/* Right Column - Options */}
              <div className="flex flex-col justify-center items-start w-full lg:w-1/2 space-y-4">
                <button onClick={() => handleOptionSelect('10-20 m3/h')} className={`w-full px-6 py-3 text-left border rounded-full ${selectedOptions.includes('10-20 m3/h') ? 'bg-[#556423] text-white' : 'bg-white'}`}>
                  10-20 m3/h
                </button>
                <button onClick={() => handleOptionSelect('15-50 m3/h')} className={`w-full px-6 py-3 text-left border rounded-full ${selectedOptions.includes('15-50 m3/h') ? 'bg-[#556423] text-white' : 'bg-white'}`}>
                  15-50 m3/h
                </button>
                <button onClick={() => handleOptionSelect('80-120 m3/h')} className={`w-full px-6 py-3 text-left border rounded-full ${selectedOptions.includes('80-120 m3/h') ? 'bg-[#556423] text-white' : 'bg-white'}`}>
                  80-120 m3/h
                </button>
                <button onClick={() => handleOptionSelect('oltre i 100 m3/h')} className={`w-full px-6 py-3 text-left border rounded-full ${selectedOptions.includes('oltre i 100 m3/h') ? 'bg-[#556423] text-white' : 'bg-white'}`}>
                  oltre i 100 m3/h
                </button>
                <button onClick={() => handleOptionSelect('oltre i 200 m3/h')} className={`w-full px-6 py-3 text-left border rounded-full ${selectedOptions.includes('oltre i 200 m3/h') ? 'bg-[#556423] text-white' : 'bg-white'}`}>
                  oltre i 200 m3/h
                </button>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button
            onClick={handleSubmit}
            style={{ borderWidth: '3px' }}
            className="border-[#556423] text-[#556423] text-xl lg:text-3xl uppercase font-bold py-4 w-full lg:w-60 rounded-full mt-10 lg:mt-20"
          >
            {t('azionamento.cta')}
          </button>
        </main>

        {/* Footer */}
        <footer className="text-center py-6 bg-[#556423] text-white">
          <button>
            <p className="inline-flex items-center">
              {t('azionamento.footer')}
              <span className="font-bold ml-2">
                <img
                  src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                  alt="Pezzolato Logo"
                  className="w-32"
                />
              </span>
            </p>
          </button>
        </footer>
      </div>

      {/* Importo Notifiche */}
      <Toast show={show} setShow={setShow} titolo={titolo} messaggio={messaggio} />
    </>
  );
}