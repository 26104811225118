// Context for managing user selections across multiple pages
import { createContext, useState } from 'react';

export const SelectionsContext = createContext();

export const SelectionsProvider = ({ children }) => {
    const [selections, setSelections] = useState({
        azionamento: [],
        produttivita: [],
        alimentazione: [],
        materiale: [],
        tipo_produzione: []
    });

    return (
        <SelectionsContext.Provider value={{ selections, setSelections }}>
            {children}
        </SelectionsContext.Provider>
    );
};